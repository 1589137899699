import { Link } from 'react-router-dom';
// import shapeImage1 from '../../assets/images/shape/shape-8.png';
import logo from '../../../assets/images/logo-2.png';
import { useResponsive } from 'component/useResponsive';
// import footerImage from 'assets/images/banner/footer-image.png';
import { Icon } from '@iconify/react';
import { color } from 'assets/css/color/color';

export default function CommingSoonFooter() {
  const { screenType } = useResponsive();
  return (
    <>
      <footer
        className="main-footer bg-black"
        style={{
          // backgroundImage: `url(${footerImage})`,
          minHeight: '90vh',
          backgroundSize: 'cover',
          paddingTop: '0rem',
          paddingBottom: screenType === 'mobile' ? '1rem' : '2rem',
          backgroundPosition: screenType === 'desktop' ? '' : 'center'
        }}>
        <div className="widget-section border-0 ">
          <div className="auto-container">
            <div
              className="row clearfix"
              style={{ marginBottom: screenType === 'desktop' ? '-6rem' : '-3rem' }}>
              <div className="col-lg-4 col-md-6 col-sm-4 footer-column">
                <div
                  className="footer-widget logo-widget "
                  style={{ display: 'grid', justifyContent: 'left', textAlign: 'left' }}>
                  <figure className="footer-logo">
                    <Link to="/">
                      <img src={logo} alt="" />
                    </Link>
                  </figure>

                  {/* <p
                    className="text-left text-white"
                    style={{ fontSize: '0.75rem', lineHeight: '1rem' }}>
                    SliverBack Card is issued by CFSB, Member FDIC, pursuant to a license by
                    mastercard International.
                  </p> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 footer-column">
                <div className="footer-widget links-widget ml_40">
                  <div className="widget-title">
                    <h6>Explore</h6>
                  </div>
                  <div className="widget-content">
                    <ul //className="links-list clearfix "
                      style={{ listStyle: 'none !important', fontSize: '14px', marginTop: -15 }}>
                      {/* <li className="my-2">
                        <Link to="/about-us">About Us</Link>
                      </li>
                      <li className="my-2">
                        <Link to="/faq">FAQ</Link>
                      </li>
                      <li className="my-2">
                        <Link to="/privacy-security">Privacy & Security</Link>
                      </li>
                      <li className="my-2">
                        <Link to="/program-fees">Program Fee</Link>
                      </li> */}
                      <li className="my-2">
                        <Link to="/silverback/terms-condition">Terms & Conditions</Link>
                      </li>

                      <li className="my-2">
                        <Link to="/silverback/privacy-policy">Privacy Policies</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-4 footer-column  pt-lg-2 pt-sm-0 mt-sm-0 ">
                <div className="footer-widget links-widget">
                  <div
                    className="widget-content"
                    style={{
                      marginTop: screenType === 'mobile' ? '-1.2rem' : '1.5rem'
                    }}>
                    <ul //className="links-list clearfix "
                      style={{ listStyle: 'none !important', fontSize: '14px' }}>
                      {/* <li className="my-2">
                        <Link to="/silverback/disclaimer">Disclaimer</Link>
                      </li> */}
                      <li className="my-2">
                        <Link
                          target="_blank"
                          to="https://netevia.com/legal/consumer-banking/cardholder-agreement/">
                          Cardholder Agreement
                        </Link>
                      </li>
                      {/* <li className="my-2">
                        <Link target="_blank" to="https://netevia.com/legal/pci-compliance/">
                          PCI Compliance
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>

              <hr className="textLinearBackgroud my-2" />
            </div>
          </div>
        </div>

        <div className="auto-container mt-3">
          <div
            className="row clearfix"
            style={{ marginBottom: screenType === 'desktop' ? '-6rem' : '-3rem' }}>
            <div className="col-lg-4 col-md-6 col-sm-12 footer-column">
              <div
                className="footer-widget logo-widget"
                style={{ display: 'grid', justifyContent: 'center', textAlign: 'left' }}>
                <div className="row">
                  <div className="col-1">
                    <Icon icon="mdi:location" width={20} />
                  </div>
                  <div className="col">
                    <p>United States</p>
                  </div>
                </div>
                <div className="widget-title" style={{ marginTop: '-2rem' }}>
                  <h6>Address</h6>
                </div>
                <p
                  className=" text-left text-white"
                  style={{
                    marginTop: -20,
                    fontSize: '0.75rem',
                    lineHeight: '1rem'
                  }}>
                  © 2024 Silver Backs Corp. 3363 NE 163rd St, Suite 606 North Miami Beach, FL 33160
                </p>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-7 col-sm-12 footer-column"
              style={{
                marginTop: screenType === 'mobile' ? '-2rem' : ''
              }}>
              <div className="footer-widget links-widget ml_20">
                <div className="widget-title">
                  <h6>Contact Information</h6>
                </div>
                <div className="widget-content" style={{ marginTop: -20 }}>
                  <ul //className="links-list clearfix "
                    style={{ listStyle: 'none !important', fontSize: '16px' }}>
                    <li className="my-1">
                      <div className="d-flex">
                        <a href="tel:+18449643725" target="_blank" rel="noreferrer">
                          <Icon height={15} icon="ion:call-outline" />
                          &nbsp;
                          <span
                            style={{
                              color: color.websiteHighLightColor,
                              fontWeight: 600,
                              fontSize: '0.75rem',
                              lineHeight: '1rem'
                            }}>
                            1-844-964-3725
                          </span>
                        </a>
                      </div>
                    </li>
                    <li className="my-1">
                      <div className="d-flex">
                        <a
                          href="mailto:support@silverbackmobile.com"
                          target="_blank"
                          rel="noreferrer">
                          <Icon height={15} icon="carbon:email" />
                          &nbsp;
                          <span
                            style={{
                              color: color.websiteHighLightColor,
                              fontWeight: 600,
                              fontSize: '0.75rem',
                              lineHeight: '1rem'
                            }}>
                            support@silverbackmobile.com
                          </span>
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 col-sm-12 footer-column"
              style={{
                marginTop: screenType === 'mobile' ? '0.1rem' : '',
                marginBottom: screenType === 'mobile' ? '1rem' : ''
              }}>
              {/* <div
                className="footer-widget logo-widget"
                style={{ display: 'grid', justifyContent: 'center', textAlign: 'left' }}>
                <div className="widget-title">
                  <h6>Technology Partnership</h6>
                </div>
                <p
                  className=" text-left text-white"
                  style={{
                    marginTop: -20,
                    fontSize: '0.75rem',
                    lineHeight: '1rem'
                  }}>
                  Silverback Corp is a technology partner of Netevia, a financial technology
                  company. The Netevia card is issued by CFSB (Commercial Financial Services Bank),
                  a Member FDIC institution, under a license granted by Mastercard International.
                  Should the member bank holding your balance fail, you are insured for up to
                  $250,000 of your money in the account.Please add this to end of technology
                </p>
              </div> */}
            </div>
          </div>
        </div>
        {/* <div className="auto-container " style={{ marginTop: '5rem' }}>
          <div
            className="col-lg-6 col-md-6 col-sm-12 footer-column"
            style={{
              marginTop: screenType === 'mobile' ? '0.1rem' : '',
              marginBottom: screenType === 'mobile' ? '1rem' : ''
            }}>
            <p
              className="text-white"
              style={{
                fontSize: '0.75rem',
                lineHeight: '1rem'
              }}>
              Register your card for FDIC insurance eligibility and other protections. Your funds
              will be held at or transferred to CFSB, an FDIC-insured institution. Once there, your
              funds are insured up to $250,000 by the FDIC in the event CFSB fails if specific
              deposit insurance requirements are met and your card is registered.
             
            </p>
          </div>
        </div> */}
      </footer>
    </>
  );
}
