import React from 'react';
import { Link } from 'react-router-dom';

export default function TechSupportDetail() {
  return (
    <section className="contact-info-section centred pt_80 pb_90">
      <div className="auto-container">
        <div className="sec-title mb_70">
          <h6>Contact US</h6>
          <h4>{`We're here for you. Reach out and let us know.`}</h4>
        </div>
        <div className="row clearfix justify-content-center">
          <div className="col-lg-4 col-md-6 col-sm-12 info-column">
            <div
              className="info-block-one wow fadeInUp animated"
              data-wow-delay="300ms"
              data-wow-duration="1500ms">
              <div className="inner-box">
                <div className="icon-box">
                  <i className="icon-43"></i>
                </div>
                <h3 style={{ color: 'black' }}>Email Address</h3>
                <p style={{ color: 'black' }}>
                  <Link href="mailto:support@example.com" style={{ color: 'black' }}>
                    support@silverbackmobile.com
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 info-column">
            <div
              className="info-block-one wow fadeInUp animated"
              data-wow-delay="600ms"
              data-wow-duration="1500ms">
              <div className="inner-box">
                <div className="icon-box">
                  <i className="icon-44"></i>
                </div>
                <h3 style={{ color: 'black' }}>Phone Number</h3>
                <p style={{ color: 'black' }}>
                  <Link href="tel:14156912939" style={{ color: 'black' }}>
                    +(1)4156912939
                  </Link>{' '}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
